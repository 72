.colorBlock {
  flex: 1 0 0;
  place-self: stretch;
}
.valueR {
  background-color: var(--red-light);
  color: var(--red-dark);
  text-align: center;
}
.valueG {
  background-color: var(--green-light);
  color: var(--green-dark);
  text-align: center;
}
.valueB {
  background-color: var(--blue-light);
  color: var(--blue-dark);
  text-align: center;
}