.panel {
  background-color: var(--background-basic);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, .1);
  margin-bottom: 2.5em;
  padding: 1em min(1em, 2vw);
  width: 100%;
}
@supports not (box-shadow: 0) {
  .panel {
    border: 1px solid var(--border-basic);
  }
}
.tall {
  min-height: 50vh;
}
.wide {
  min-width: 50vw;
}