.section {
  /* To account for border if enabled */
  border: 1px solid transparent;
  
  margin-top: 1em;
  margin-bottom: .3em;
  padding: .3em min(.3em, 2vw);
}
.border {
  border: 1px solid var(--border-very-light);
  border-radius: .2em;
}