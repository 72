.grid,.row {
  --gap: .4em;
}
.grid {
  display: grid;
  gap: var(--gap);
  grid-auto-flow: row;
}
.noGap, .noGap .row {
  gap: 0;
}
.row {
  display: grid;
  gap: var(--gap);
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
}