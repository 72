.codeWrap {
  display: flex;
  height: 100%;
  width: 100%;
}
.codeWrap > * {
  flex: 1 0 0;
}
.code {
  background-color: var(--background-code);
  border: 1px solid var(--border-light);
  font-size: 1rem;
  padding: 1em;
  white-space: pre-line;
}