.candidateWrap {
  border: 1px solid var(--border-light);
  display: flex;
  font-size: 1.5em;
  flex-flow: row wrap;
  justify-content: center;
}
.candidateWrap > * {
  flex: 1 0 auto;
  margin: .3vmax;
  min-width: 5em;
  text-align: center;
}
.badge {
  max-width: min(100vw, 30vmax);
  word-wrap: break-word;
}
.editSelect {
  border: 1px dashed!important;
}
.note {
  color: var(--color-muted);
  font-size: .65em;
}

.bulkEditText {
  flex: 1 0 0;
  font-size: 1.5em;
  overflow-y: scroll;
}
