.description {
  margin: .5em;
}
.disclosure {
  color: var(--color-muted);
  font-size: .55em;
  font-family: monospace;
  margin: 5rem auto 0 auto;
  max-width: 75%;
  text-align: center;
}

.banner {
  background-color: var(--background-light);
  display: flex;
  flex-flow: column;
  height: 6em;
  margin-bottom: 2em;
  padding: .5em 0;
  justify-content: center;
  place-items: center;
}
.logo {
  margin-bottom: .5em;
}
.companyName {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.8em;
  font-weight: 600;
}
.contactWrapper {
  margin: 2em;
}
.logoWrap {
  height: 3em;
  width: 3em;
}
.contactNote {
  font-size: .75em;
}