.center {
  text-align: center;
}
.input {
  background-color: transparent;
  border: 1px solid var(--border-light);
  color: var(--color-basic);
  padding: .1em .3em;
  width: 100%;
}
.inputWrap {
  margin-bottom: .3em;
}
.button {
  background-color: transparent;
  border: 1px solid var(--border-light);
  border-radius: 3px;
  cursor: pointer;
  color: var(--color-basic);
  font-weight: 300;
  padding: .5em;
  user-select: none;
}
.buttonCap {
  /* Some browsers don't convert correctly with small-caps */
  font-size: .75em;
  text-transform: uppercase;
}
.wide {
  width: 100%;
}
.note {
  color: var(--color-muted);
  font-size: .75em;
  font-weight: 300;
}
.emoji {
  font-size: 1.5em;
}