.markWrap {
  border: 1px solid var(--border-light);
  display: flex;
  flex-flow: column;
  /* Height of symbol, border between, and name */
  height: calc(max(var(--sizing-mark-dim), var(--sizing-mark-min-dim)) + 2.3em + 1px);
  min-height: var(--sizing-mark-min-dim);
  min-width: var(--sizing-mark-min-dim);
  justify-content: stretch;
  place-items: stretch;
  width: var(--sizing-mark-dim);
}
.mark {
  background-color: var(--background-dark);
  color: var(--color-bright);
  display: flex;
  font-family: sans-serif, monospace;
  font-size: 1.33em;
  font-weight: 900;
  flex: 1 0 0;
  flex-flow: column;
  justify-content: center;
  place-items: center;
  place-self: stretch;
  text-align: center;
}
.mark a {
  color: var(--color-bright);
}
.markHover:hover {
  cursor: pointer;
  opacity: .3;
}
.markName {
  color: var(--color-faint);
  display: flex;
  font-variant: small-caps;
  font-weight: 590;
  justify-content: center;
  place-items: center;
  height: 2.3em;
  text-align: center;
}