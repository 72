.row {
  display: grid;
  grid-gap: 0 .3em;
  grid-template-columns: repeat(fit-content, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin: 1em 0;
}
.label {
  grid-area: label;
  grid-column: auto;
  grid-row: 1;
}
.value {
  grid-area: value;
  grid-column: auto;
  grid-row: 2;
}
.label, .value {
  border: 1px solid var(--border-light);
  display: flex;
  padding: .3em;
  justify-content: center;
  place-items: center;
}
.label {
  background-color: var(--background-light);
  border-bottom: none;
}