.wrap {
  display: flex;
  height: var(--sizing-mark-dim-symbol);
  fill: white;
  min-height: var(--sizing-mark-min-dim-symbol);
  min-width: var(--sizing-mark-min-dim-symbol);
  width: var(--sizing-mark-dim-symbol);
}
.wrap * {
  display: flex;
  flex: 1 0 0;
  max-width: 100%;
}