.button {
  background-color: var(--background-medium);
  border-radius: .1em;
  font-size: 1em;
  height: 2em;
  padding: 0;
  position: fixed;
  right: .1em;
  top: .1em;
  width: 2em;
}