.button {
  background-color: transparent;
  border-width: 1px;
  border-radius: 0;
  color: var(--color-basic);
  padding: .3em 0;
}
.input {
  background-color: transparent;
  color: var(--color-basic);
  margin: 0 .2em;
}
.label {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-right: 1em;
}
.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.75em;
}
.wrapper > * {
  flex: 1 0 0;
}