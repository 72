.alert {
  border: 1px solid transparent;
  padding: 1em 1.5em;
  margin-bottom: .5em;
}
.basic, .basic a {
  background-color: var(--gray-light);
  border-color: var(--gray-dark);
  color: var(--gray-dark);
}
.info, .info a {
  background-color: var(--blue-light);
  border-color: var(--blue-dark);
  color: var(--blue-dark);
}
.pass, .pass a {
  background-color: var(--green-light);
  border-color: var(--green-dark);
  color: var(--green-dark);
}
.warn, .warn a {
  background-color: var(--yellow-light);
  border-color: var(--yellow-dark);
  color: var(--yellow-dark);
}
.fail, .fail a {
  background-color: var(--red-light);
  border-color: var(--red-dark);
  color: var(--red-dark);
}