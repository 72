.header {
  border-bottom: 1px solid var(--border-light);
  color: var(--color-light);
  font-family: sans-serif;
  font-size: 1.3em;
  font-weight: bolder;
  margin: 0;
  padding: 0 1em;
  text-align: left;
  width: 100%;
}