.row {
  align-items: center;
  display: flex;
  flex-flow: row wrap;
}
.row > * {
  flex: 1 0 auto;
  margin-bottom: 1em;
}
.stretch {
  width: 100%;
}
.spaceEven {
  justify-content: space-evenly;;
}
.spaceEven > * {
  flex: 0 1 auto;
}