.main {
  color: var(--color-light);
  font-weight: bolder;
  text-decoration: underline;
}
.expand {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  place-items: center;
}