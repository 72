* {
  --sizing-mark-dim: 5em;
  --sizing-mark-min-dim: 4.3em;
  --sizing-mark-dim-symbol: 3em;
  --sizing-mark-min-dim-symbol: 2.3em;
  --sizing-font-pref: 3.5vh;
  --sizing-font-min: 10px;
  --sizing-font-max: min(5vh, 24px);
}

body.def-color, body.light-color,
body.def-color *, body.light-color * {
  --background-basic: #FAFAFA;
  --background-page: #F5F5F5;
  --background-light: #F3F3F3;
  --background-code: #F1F1F1;
  --background-disabled: #EAEAEA;
  --background-medium: #999;
  --background-dark: #666;

  --color-dark: #000;
  --color-basic: #333;
  --color-light: #666;
  --color-muted: #888;
  --color-faint: #AAA;
  --color-bright: #FAFAFA;

  --gray-dark: #555;
  --gray-light: #EEE;
  --red-dark: #500;
  --red-light: #FEE;
  --blue-dark: #005;
  --blue-light: #EEF;
  --green-dark: #050;
  --green-light: #EFE;
  --yellow-dark: #550;
  --yellow-light: #FFD;
  
  --border-very-light: #EAEAEA;
  --border-light: #DADADA;
  --border-basic: #CACACA;
  --border-dark: #AAA;
}

body.dark-color, body.dark-color * {
  --background-basic: #252525;
  --background-page: #2A2A2A;
  --background-light: #2C2C2C;
  --background-code: #2E2E2E;
  --background-disabled: #353535;
  --background-medium: #666;
  --background-dark: #999;

  --color-dark: #FFF;
  --color-basic: #CCC;
  --color-light: #AAA;
  --color-muted: #999;
  --color-faint: #777;
  --color-bright: #050505;

  --gray-dark: #EEE;
  --gray-light: #555;
  --red-dark: #FEE;
  --red-light: #500;
  --blue-dark: #EEF;
  --blue-light: #005;
  --green-dark: #EFE;
  --green-light: #050;
  --yellow-dark: #FFD;
  --yellow-light: #550;
  
  --border-very-light: #3C3C3C;
  --border-light: #555;
  --border-basic: #777;
  --border-dark: #555;
}

body {
  background-color: var(--background-page);
  color: var(--color-basic);

  overflow-y: scroll;

  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: min(var(--sizing-font-max), max(var(--sizing-font-pref), var(--sizing-font-min)));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  touch-action: manipulation;
}
button {
  background-color: transparent;
  border: 1px solid var(--border-basic);
}
input, button {
  font-size: inherit;
}

[disabled] {
  background-color: var(--background-disabled)!important;
  color: var(--color-basic)!important;
  /* Some browsers like to set this to partially transparent */
  opacity: 1;
}

ul {
  list-style-type: ' - ';
}