.gutter {
  background-color: rgba(0, 0, 0, .3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
.wrapper {
  --width: min(90vw, 65vmax);
  --height: 95vh;
  --horiz: calc((100vw - var(--width)) / 2);
  --vert: calc((100vh - var(--height)) / 2);

  background-color: var(--background-basic);
  border-radius: .1em;
  bottom: var(--vert);
  display: flex;
  flex-flow: column;
  left: var(--horiz);
  padding: 3vmin 0;
  position: fixed;
  right: var(--horiz);
  top: var(--vert);
}
.content {
  box-shadow: inset 0 -3px 5px 1px rgba(0, 0, 0, .15);
  border-bottom: 1px solid var(--border-light);
  border-top: 1px solid var(--border-light);
  display: flex;
  flex: 1 0 0;
  flex-flow: column;
  margin: 1em 0;
  overflow-y: scroll;
  padding: 1em;
}
.topCloseButton {
  align-self: flex-end;
  color: var(--color-muted);
  font-size: .85em;
  font-weight: bolder;
  height: 2em;
  line-height: 1em;
  margin-right: .5em;
  width: 2em;
}
.bottomCloseButton {
  align-self: center;
  flex: 0 1 auto;
  min-height: 3em;
  width: 50%;
}

.bodyScrollBlock {
  overflow: hidden!important;
}

.scrollMarker {
  background-color: var(--background-basic);
  border: 1px solid var(--border-light);
  bottom: 11%;
  color: var(--color-muted);
  font-size: .75em;
  left: 50%;
  padding: .3em;
  position: absolute;
  text-align: center;
  transform: translate(-50%);
  width: 90%;
}