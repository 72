.selectionBar {
  display: flex;
  font-size: .75em;
  font-weight: lighter;
  justify-content: space-between;
}
.selection {
  border: 1px dashed var(--border-light);
  font-size: 2.15em;
  font-weight: lighter;
  padding: .15em;
  text-align: center;
}
.finalSelection {
  border: 1px solid var(--border-dark);
  word-wrap: break-word;
}

.note {
  color: var(--color-muted);
  font-size: .75em;
}