.wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  margin: 2vh 0;
}
.wrapper > * {
  flex: 0 1 30%;
  padding: .3em 0;
  margin-bottom: .5em;
}